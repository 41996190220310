<template>
  <Layout class="GoodsItemCreateOrEdit">
    <h2 style="padding-left: 160px;">
      新建商品
    </h2>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="small"
      label-width="260px"
    >
      <el-form-item
        label="APP ID"
        prop="appId"
      >
        <el-select
          v-model="formData.appId"
          clearable
          placeholder="选择APP ID"
        >
          <el-option
            v-for="item in appList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="包名"
        prop="pApplicationId"
      >
        <el-input
          v-model="formData.pApplicationId"
          clearable
          placeholder="客户端包名"
        />
      </el-form-item>

      <el-form-item
        label="商品名称"
        prop="name"
      >
        <el-input
          v-model="formData.name"
          clearable
          placeholder="商品名称"
        />
      </el-form-item>

      <el-form-item
        label="简介"
        prop="description"
      >
        <el-input
          v-model="formData.description"
          clearable
          placeholder="简介"
        />
      </el-form-item>

      <el-form-item
        label="内容"
        prop="content"
      >
        <el-input
          v-model="formData.content"
          type="textarea"
          clearable
          placeholder="内容"
        />
      </el-form-item>

      <el-form-item
        label="渠道"
        prop="channel"
      >
        <el-select
          v-model="formData.channel"
          clearable
          placeholder="选择渠道"
        >
          <el-option
            v-for="item in goodsChannelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="类型"
        prop="type"
      >
        <el-select
          v-model="formData.type"
          clearable
          placeholder="选择类型"
        >
          <el-option
            v-for="item in goodsTypelList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="时间类型"
        prop="timeType"
      >
        <el-select
          v-model="formData.timeType"
          clearable
          placeholder="选择时间类型"
        >
          <el-option
            v-for="item in timeTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!-- TODO 校验数字 -->
      <el-form-item
        label="时间数量"
        prop="timeNumber"
      >
        <el-input
          v-model="formData.timeNumber"
          clearable
          placeholder="时间数量(多少天|月|年)"
        />
      </el-form-item>
      <!-- 联动 时间数量 -> 订阅商品的总时间-->
      <el-form-item
        label="订阅商品的总时间"
        prop="subscriptionTime"
      >
        <el-input
          v-model="formData.subscriptionTime"
          clearable
          placeholder="订阅商品的总时间(小时)"
        />
      </el-form-item>

      <el-form-item
        label="第三方支付平台商品ID"
        prop="thirdGoodsId"
      >
        <el-input
          v-model="formData.thirdGoodsId"
          clearable
          placeholder="第三方支付平台商品ID"
        />
      </el-form-item>

      <el-form-item
        label="金币"
        prop="gold"
      >
        <el-input
          v-model="formData.gold"
          clearable
          placeholder="金币"
          @input="computedTopupCardTime"
        />
      </el-form-item>
      <!--  联动金币 -> 时长卡时间-->
      <el-form-item
        label="时长卡时间"
        prop="topupCardTime"
      >
        <el-input
          v-model="formData.topupCardTime"
          clearable
          placeholder="时长卡时间"
          disabled
        />
      </el-form-item>

      <el-form-item
        label="商品价格"
        prop="price"
      >
        <el-input
          v-model="formData.price"
          clearable
          placeholder="商品价格"
        />
      </el-form-item>

      <el-form-item
        label="币种"
        prop="currency"
      >
        <el-input
          v-model="formData.currency"
          clearable
          placeholder="币种(大写)"
        />
      </el-form-item>
      <!-- input text int-->
      <el-form-item
        label="实验组"
        prop="experimentalGroup"
      >
        <el-input
          v-model="formData.experimentalGroup"
          placeholder="请填写实验组代码"
        />
      </el-form-item>

      <el-form-item
        label="是否删除"
        prop="isDel"
      >
        <el-switch
          v-model="formData.isDel"
          active-text="是"
          inactive-text="不是"
          :active-value="1"
          :inactive-value="2"
        />
      </el-form-item>

      <!-- 数字 -->
      <el-form-item
        label="折扣(打几折)"
        prop="discount"
      >
        <el-input
          v-model="formData.discount"
          clearable
          placeholder="折扣(打几折)"
        />
      </el-form-item>

      <el-form-item
        label="上架状态"
        prop="status"
      >
        <el-switch
          v-model="formData.status"
          active-text="上架"
          inactive-text="下架"
          :active-value="1"
          :inactive-value="2"
        />
      </el-form-item>

      <el-form-item
        label="订阅商品是否限制商品"
        prop="isLimit"
      >
        <el-switch
          v-model="formData.isLimit"
          active-text="是"
          inactive-text="不是"
          :active-value="1"
          :inactive-value="2"
        />
      </el-form-item>

      <el-form-item
        label="限制的时间类型"
        prop="limitTimeType"
      >
        <el-select
          v-model="formData.limitTimeType"
          clearable
          placeholder="选择时间类型"
        >
          <el-option
            v-for="item in timeTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="限制的时间数量"
        prop="limitTimeNumber"
      >
        <el-input
          v-model="formData.limitTimeNumber"
          clearable
          placeholder="限制的时间数量(限制多少天|月|年)"
        />
      </el-form-item>

      <el-form-item
        label="总共限制"
        prop="limitTotalAmount"
      >
        <el-input
          v-model="formData.limitTotalAmount"
          clearable
          disabled
          placeholder="多少天|月|年一共限制多少秒"
        />
      </el-form-item>

      <el-form-item
        label="平均限制"
        prop="limitPerAmount"
      >
        <el-input
          v-model="formData.limitPerAmount"
          clearable
          disabled
          placeholder="1天|月|年限制多少秒"
        />
      </el-form-item>

      <el-form-item
        label="排序"
        prop="msorts"
      >
        <el-input
          v-model="formData.msorts"
          clearable
          placeholder="排序"
        />
      </el-form-item>

      <el-form-item
        label="是否首次充值商品"
        prop="isFirstRecharge"
      >
        <el-switch
          v-model="formData.isFirstRecharge"
          active-text="是"
          inactive-text="不是"
          :active-value="1"
          :inactive-value="0"
        />
      </el-form-item>

      <el-form-item
        label="备注"
        prop="remark"
      >
        <el-input
          v-model="formData.remark"
          type="textarea"
          clearable
          placeholder="备注信息，一些说明记录"
        />
      </el-form-item>

      <el-form-item
        label="显示价格"
        prop="uiShowOriginOrice"
      >
        <el-input
          v-model="formData.uiShowOriginOrice"
          clearable
          placeholder="App 上显示的原始价格"
        />
      </el-form-item>

      <el-form-item
        label="ios 促销商品优惠标志"
        prop="iosDiscountIdentify"
      >
        <el-switch
          v-model="formData.iosDiscountIdentify"
          active-text="是"
          inactive-text="不是"
          :active-value="1"
          :inactive-value="2"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          :loading="submitLoading"
          type="primary"
          class="submit"
          @click="handleSubmit"
        >
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import {
  appList,
  timeTypeList,
  goodsChannelList,
  goodsTypelList
} from '@/utils/selectOptions.js'
import { cloneDeep } from 'lodash'

import {
  addgoods
} from '@/api/RechargeSystem.js'

const baseMap = {
  1: 24 * 60 * 60,
  2: 30 * 24 * 60 * 60,
  3: 365 * 30 * 24 * 60 * 60
}

export default {
  data () {
    return {
      appList,
      timeTypeList,
      goodsChannelList,
      goodsTypelList,
      formData: {
        isDel: 2,
        isLimit: 2,
        status: 1,
        iosDiscountIdentify: 2,
        isFirstRecharge: 0
      },
      rules: {
        appId: { required: true, message: 'App ID必选' },
        // pApplicationId: { 1required: true, message: '包名必填' },
        name: { required: true, message: '商品名称必填' },
        channel: { required: true, message: '支付渠道必选' },
        type: { required: true, message: '商品类型必选' },
        thirdGoodsId: { required: true, message: '第三方平台商品ID必填, 举例: PayPal的商品ID' },
        price: { required: true, message: '商品价格必填' },
        currency: { required: true, message: '币种必填, 举例: USD' },
        experimentalGroup: { required: true, message: '实验组必填, 举例: 0:基准组 1:V1体验组 2:V2体验组' }
      },
      submitLoading: false
    }
  },
  methods: {
    computedSubscriptionTime () {
      if (!this.formData.timeType || !this.formData.timeNumber || isNaN(this.formData.timeNumber)) {
        this.formData.subscriptionTime = 0
      } else {
        const base = baseMap[this.formData.timeType]
        this.formData.subscriptionTime = base * this.formData.timeNumber
      }
    },
    computedTopupCardTime () {
      if (!this.formData.gold || isNaN(this.formData.gold)) {
        this.formData.topupCardTime = 0
      } else {
        this.formData.topupCardTime = Math.round(this.formData.gold / 2 * 60)
      }
    },
    handleSubmit () {
      this.submitLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          addgoods(this.formateForm())
            .then(res => {
              if (res.code === 200) {
                this.$message.success('新建商品成功')
              }
            })
            .finally(() => {
              this.submitLoading = false
            })
        } else {
          this.submitLoading = false
        }
      })
    },
    formateForm () {
      const formData = cloneDeep(this.formData)
      formData.price = formData.price * 10000
      // 小时转换为秒
      formData.subscriptionTime = formData.subscriptionTime * 60 * 60
      return formData
    }
  }
}
</script>

<style lang="less" scoped>

  .GoodsItemCreateOrEdit{
    .el-input,
    .el-textarea {
      width: 420px;
    }
    .submit {
      width: 420px;
    }
  }
  </style>
